<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <div>
      <stripe-checkout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sessionId"
      />
      <overlay-alert
        :show-progress="showProgress"
        :process-alert="processAlert"
      />
      <purchase-confirm-details
        v-if="showConfirmForm"
        @purchase-details="purchaseConfirm"
        @closed="showConfirmForm = false"
      />
      <contact-for-purchase
        v-if="ShowContactForm"
        @closed="ShowContactForm = false"
      />
      <Purchase-alert-dialog
        v-if="showAlert"
        title="Do you want to proceed ?"
        :message="message"
        confirm-button="Yes"
        cancel-button="No"
        @closed="showAlert = false"
        @confirmed="showPurchaseConfirmForm()"
      />
      <purchase-prevent-alert
        v-if="showForm"
        :product-type="defaultPlanType"
        @closed="showForm = false"
      />
    </div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-credit-card-check
      </v-icon> Subscription Plans
    </h2>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <v-row
        align="center"
        justify="center"
      >
        <div class="main-div">
          <input
            id="toggle"
            v-model="isPricingPlanThreeYear"
            class="input"
            type="checkbox"
            @change="showPricingPlans()"
          >
          <label
            class="label"
            for="toggle"
          >
            <div class="float-left">
              Yearly
            </div>
            <div class="switch">
              <span class="slider round" />
            </div>
            <div class="float-right">
              3-Years
            </div>
          </label>
        </div>
      </v-row>
      <div v-if="isPaymentPlansAvailable">
        <v-card
          class="my-vcard-style"
        >
          <v-container
            class="pa-4 text-center"
          >
            <v-row>
              <v-col
                v-for="(item, i) in productPaymentPlans"
                :key="i"
                md="4"
              >
                <div v-if="item.active === true && item.prices.length > 0">
                  <template v-for="(price, index) in item.prices">
                    <div
                      v-if="plansOnPricingPeriod(price.active, price.type)"
                      :key="index"
                    >
                      <v-hover
                        v-slot="{ hover }"
                      >
                        <v-card
                          :elevation="hover ? 12 : 1"
                          :class="{ 'on-hover': hover }"
                          style="border:1px solid #37474F; border-radius:25px"
                        >
                          <v-card-title class="text-h6">
                            <v-row
                              class="fill-height flex-column"
                              align="center"
                              justify="center"
                            >
                              <v-col cols="12">
                                <br>
                                <div>
                                  <p class="plan-style">
                                    {{ item.product_tier.toUpperCase() }}
                                  </p>
                                </div>
                                <br>
                                <div>
                                  <p class="amount-style">
                                    <span class="currency-style">{{ price.currency.toUpperCase() }}</span> {{ price.display_price }}
                                  </p>
                                  <p class="amnt-month-style">
                                    Monthly/Screen
                                  </p>
                                </div>
                              </v-col>
                              <v-col
                                cols="12"
                              >
                                <div>
                                  <p class="amnt-year-style">
                                    Total: {{ price.currency.toUpperCase() }} {{ price.amount | amountConverter }} /{{ price.pricing_period | periodCoverter }}Year
                                  </p>
                                </div>
                                <div>
                                  <v-btn
                                    class="btn-style"
                                    color="#FF3700"
                                    @click="purchase(price.id, price.type,item.name)"
                                  >
                                    Purchase
                                  </v-btn>
                                </div>
                              </v-col>
                              <br>
                              <div>
                                <p class="description-style">
                                  <v-icon color="#2E7D32">
                                    mdi-check
                                  </v-icon>
                                  Media Player Software<br><span class="product-name">BannerEx</span>
                                </p>
                              </div>
                              <br>
                            </v-row>
                          </v-card-title>
                        </v-card>
                      </v-hover>
                      <div v-if="price.display_description !== null">
                        <p class="tooltip-style">
                          <v-icon
                            left
                            color="#2E7D32"
                          >
                            mdi-tag
                          </v-icon>{{ price.display_description }}
                        </p>
                      </div>
                    </div>
                  </template>
                </div>
                <div v-if="item.active === true && item.prices.length === 0">
                  <v-hover
                    v-slot="{ hover }"
                  >
                    <v-card
                      :elevation="hover ? 12 : 1"
                      :class="{ 'on-hover': hover }"
                      style="border:1px solid #37474F; border-radius:25px"
                    >
                      <v-card-title class="text-h6">
                        <v-row
                          class="fill-height flex-column"
                          align="center"
                          justify="center"
                        >
                          <v-col cols="12">
                            <br>
                            <div>
                              <p class="plan-style">
                                {{ item.product_tier.toUpperCase() }}
                              </p>
                            </div>
                            <br>
                            <div>
                              <p class="amount-style">
                                Custom
                              </p>
                              <p class="amnt-month-style">
                                as required
                              </p>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <div>
                              <p class="amnt-year-style">
                                Total: Quote Based
                              </p>
                            </div>
                            <div>
                              <v-btn
                                class="btn-style"
                                color="#FF3700"
                                @click="contactUs()"
                              >
                                Contact Us
                              </v-btn>
                            </div>
                          </v-col>
                          <br>
                          <div>
                            <p class="description-style">
                              <v-icon color="#2E7D32">
                                mdi-check
                              </v-icon>
                              Media Player Software<br><span class="product-name">BannerEx</span>
                            </p>
                          </div>
                          <br>
                        </v-row>
                      </v-card-title>
                    </v-card>
                  </v-hover>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
      <br>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
 import spinner from 'src/views/dashboard/component/SpinnerCentre';
 import { StripeCheckout } from '@vue-stripe/vue-stripe';
 import { stripePublishKey } from 'src/app-globals';
 import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
 import PurchaseConfirmDeatils from './PurchaseConfirmDetails.vue';
 import global from 'src/mixins/global';
 import ContactForPurchase from './ContactForPurchase.vue';
 import DeleteDialog from 'src/views/dashboard/component/DeleteDialog.vue';
 import PurchasePreventAlert from 'src/views/dashboard/ProfileSettings/payments/PurchasePreventAlert.vue';
 import Constants from 'src/constants';

 export default {
    name: 'ProductPaymentPlans',
    components: {
      'centre-spinner': spinner,
      'stripe-checkout': StripeCheckout,
      'overlay-alert': OverlayAlert,
      'purchase-confirm-details': PurchaseConfirmDeatils,
      'contact-for-purchase': ContactForPurchase,
      'Purchase-alert-dialog': DeleteDialog,
      'purchase-prevent-alert': PurchasePreventAlert,
    },
    filters: {
      amountConverter (amount) {
        return amount / 100;
      },
      periodCoverter (period) {
        if (period === 'threeYearly') {
          return 3;
        }
        return 1;
      },
    },
    mixins: [global],
    data () {
      return {
        loading: false,
        publishableKey: stripePublishKey,
        sessionId: '',
        priceId: '',
        isPricingPlanThreeYear: false,
        intervalCount: 1,
        showProgress: false,
        processAlert: '',
        showConfirmForm: false,
        ShowContactForm: false,
        userCurrency: '',
        priceType: '',
        showAlert: false,
        message: '',
        productType: '',
        showForm: false,
        defaultPlanType: '',
      };
    },
    computed: {
      productPaymentPlans () {
        const productPaymentPlans = this.$store.getters['payments/getProductPaymentPlans'];
        const index = [0, 2, 1];
        const sortedPlanDetails = index.map(i => productPaymentPlans[i]);
        return sortedPlanDetails;
      },
      userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
      checkoutSession () {
        return this.$store.getters['payments/getCheckoutSession'];
      },
      userLocationDetails () {
        return this.$store.getters['userprofile/getUserLocation'];
      },
      isPaymentPlansAvailable () {
        if (this.productPaymentPlans[0] !== undefined) {
          return true;
        }
        return false;
      },
      subscriptionsHistory () {
        return this.$store.getters['payments/getSubscriptionsHistory'];
      },
      oneTimePaymentHistory () {
        return this.$store.getters['payments/getOneTimePaymentHistory'];
      },
      paymentHistory () {
        const history = [...this.subscriptionsHistory, ...this.oneTimePaymentHistory];
        const sortedList = history.sort((a, b) => {
          return new Date(a.payment_date) - new Date(b.payment_date);
        });
        return sortedList;
      },
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.permissionCheck('view-product-by-type') === false) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    async mounted () {
      this.loading = true;
      await this.permissionCheck('view-product-by-type') && await this.loadOnMount();
      await this.fetchPaymentHistory();
      this.loading = false;
    },
    methods: {
      async loadOnMount () {
        await this.$store.dispatch('userprofile/fetchUserLocation').then(response => {
          this.userCurrency = this.userLocationDetails.currency.toUpperCase();
          this.$store.dispatch('payments/fetchProductPaymentPlans');
          this.$store.dispatch('userprofile/fetchUserProfile');
          this.showPricingPlans();
        }).catch(() => {
          this.$router.push({ name: 'Dashboard' });
        });
      },
      plansOnPricingPeriod (priceStatus, pricingType) {
        if (priceStatus === true && ((pricingType === 'recurring' && this.intervalCount === 1) ||
         (pricingType === 'one_time' && this.intervalCount === 3))) {
          return true;
        }
        return false;
      },
      showPricingPlans () {
        if (this.isPricingPlanThreeYear === true) {
          this.intervalCount = 3;
          return;
        }
        this.intervalCount = 1;
      },
      purchase (priceId, priceType, productType) {
        if (this.permissionCheck('create-payment-session') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.productType = productType;
        this.priceId = priceId;
        this.priceType = priceType;
        if (this.paymentHistory.length !== 0) {
          this.purchasePreventCheck();
        } else {
          this.message = 'You have selected the default plan as' + ' "' + productType + '" ' + 'and will be allowed to purchase ONLY the same plan type in future.';
          this.showAlert = true;
        }
      },
      purchasePreventCheck () {
        if (this.paymentHistory[0].plan === this.productType) {
          this.showConfirmForm = true;
        } else {
          this.defaultPlanType = this.paymentHistory[0].plan;
          this.showForm = true;
        }
      },
      showPurchaseConfirmForm () {
        this.showAlert = false;
        this.showConfirmForm = true;
      },
      contactUs () {
        this.ShowContactForm = true;
      },
      async purchaseConfirm (deviceQuantity, paymentMethod) {
        this.showConfirmForm = false;
        this.showProgress = true;
        this.processAlert = 'Verifying...';
        await this.$store.dispatch('payments/createCheckoutCustomer', {
          description: `${this.userProfile.first_name} - ${this.userProfile.external_id}`,
          payment_method_type: paymentMethod,
        }).then(response => {
          this.createCheckoutSession(deviceQuantity, paymentMethod);
        }).catch(() => {
          this.showProgress = false;
        });
      },
      async createCheckoutSession (deviceQuantity, paymentMethod) {
        this.processAlert = 'Confirming...';
        await this.$store.dispatch('payments/createCheckoutSession', {
          price_id: this.priceId,
          quantity: parseInt(deviceQuantity),
          payment_method_types: paymentMethod.split(','),
          mode: this.priceType === 'recurring' ? 'subscription' : 'payment',
          digi_board_licences: null,
        }).then(response => {
          this.sessionId = this.checkoutSession.stripe_id;
          this.redirectToCheckout();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      redirectToCheckout () {
        this.processAlert = 'Redirecting...';
        this.$refs.checkoutRef.redirectToCheckout();
      },
       async fetchPaymentHistory () {
        await this.$store.dispatch('payments/fetchSubscriptionsHistory');
        await this.$store.dispatch('payments/fetchOneTimePaymentHistory');
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 25px;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.div-style {
  margin-right: auto;
  margin-left: auto;
}
.plan-style {
  color: #FF3700;
  font-size: 28px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.amount-style {
  color: #37474F;
  font-size: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.amnt-month-style {
  color: #37474F;
  font-size: 13PX;
}
.amnt-year-style {
  color: #37474F;
  font-size: 15PX;
  font-weight: bold;
}
.btn-style {
  margin-left: 15px;
}
.label {
  pointer-events: none;
  display: flex;
  align-items: center;
}
.switch,
.input:checked + .label .left,
.input:not(:checked) + .label .right {
  pointer-events: all;
  cursor: pointer;
}
.input {
  display: none;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #37474F;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .label .slider {
  background-color: #FF3700;
}
input:focus + .label .slider {
  box-shadow: 0 0 1px #FF3700;
}
input:checked + .label .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.float-left, .float-right {
  margin: 0 .5em;
  font-weight: bold;
  font-size: 25px;
  color: #37474F;
  font-family:'Times New Roman', Times, serif;
}
.main-div {
  display: flex;
  justify-content: center;
}
.description-style {
  color: #37474F;
  font-size: 15PX;
  font-family: 'Times New Roman', Times, serif;
}
.product-name {
  color: #546E7A;
  font-size: 18PX;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
}
.currency-style {
 font-size: 25px;
 font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.tooltip-style {
  color: #2E7D32;
  font-size: 18PX;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
}
.test {
  color: #ECEFF1;
}
</style>
