<template>
  <section>
    <v-dialog
      v-model="showModal"
      max-width="700px"
    >
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="modal-title-style">
          <v-icon class="modal-icon-style">
            mdi-phone
          </v-icon> Contact Us
        </h2>
        <v-card-text>
          <v-form>
            <v-container>
              <div class="div-style">
                <p
                  class="my-p-style"
                >
                  Sorry, You are not permitted to purchase a new plan as your default plan type is of <span class="product-type-style"> "{{ productType }}". </span> Please contact our support team for more details.
                </p>
                <p
                  class="contact-style"
                >
                  <font>
                    <v-icon color="#37474F">
                      mdi-email
                    </v-icon>
                    info@dbsystems.com.au
                  </font>
                </p>
                <p
                  class="contact-style"
                >
                  <font>
                    <v-icon color="#37474F">
                      mdi-phone
                    </v-icon>
                    0401 750 181 (Melbourne)
                  </font>
                </p>
                <p
                  class="contact-style"
                >
                  <font>
                    <v-icon color="#37474F">
                      mdi-phone
                    </v-icon>
                    0400 482 818 (Sydney)
                  </font>
                </p>
              </div>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="showModal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>

export default {
  name: 'PurchasePreventAlert',
  props: {
    productType: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
    };
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
};
</script>
<style scoped>
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #C62828;
  font-family:'Times New Roman', Times, serif;
  margin-bottom: 20px;
}
.modal-title-style {
  color: #37474F;
  margin-left: 30px;
}
.modal-icon-style {
  color: #37474F;
  font-size: 45px;
}
.div-style {
    text-align: center;
}
.contact-style {
    font-size: 18px;
    color: #37474F;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
}
.product-type-style {
  color: #37474F;
  font-weight: bold;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
}
</style>
